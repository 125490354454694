import dayjs from "dayjs";

const CryptoJS = require("crypto-js");

export const disableFutureDate = (current: any) => {
  return current && current > dayjs().startOf("day");
};

export const getInitials = (fullName: string) => {
  if (fullName) {
    const nameArray = fullName?.split(" ");
    const firstInitial = nameArray[0]?.charAt(0);
    const lastInitial = nameArray[1]?.charAt(0);
    return fullName ? `${firstInitial}${lastInitial}` : "NA";
  } else {
    return "-";
  }
};

export const validateFirstName = (_: any, value: string) => {
  if (!value || value.trim() === "") {
    return Promise.resolve();
  }

  if (value.length < 3 || value.length > 16) {
    return Promise.reject("First name must be between 3 and 16 characters.");
  }

  if (!/^[A-Za-z ]+$/.test(value)) {
    return Promise.reject("Please enter valid alphabetic characters.");
  }

  return Promise.resolve();
};

export const validateLastName = (_: any, value: string) => {
  if (!value || value.trim() === "") {
    return Promise.resolve();
  }

  if (value.length < 3 || value.length > 16) {
    return Promise.reject("Last name must be between 3 and 16 characters");
  }

  if (!/^[A-Za-z]+$/.test(value)) {
    return Promise.reject("Please enter valid alphabetic characters only.");
  }

  return Promise.resolve();
};

export const validatePassword = (_: any, value: any) => {
  if (value.length) {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{12,}$/;
    if (!passwordRegex.test(value)) {
      return Promise.reject(new Error("Invalid password!"));
    }

    return Promise.resolve();
  }
  return Promise.resolve();
};

export const validatePhoneNumber = (_: any, value: string) => {
  const trimmedValue = value.trim();

  if (!trimmedValue) {
    return Promise.resolve();
  }

  const numericPattern = /^[6-9]\d{9}$/;

  if (!numericPattern.test(trimmedValue)) {
    return Promise.reject("Please enter a valid 10-digit phone number.");
  }

  const repeatingDigitPattern = /^(.)\1{9}$/;

  if (repeatingDigitPattern.test(trimmedValue)) {
    return Promise.reject("Please enter a valid 10-digit phone number.");
  }

  return Promise.resolve();
};

export const validateAmount = (amountType: string) => (_: any, value: any) => {
  if (!value) {
    return Promise.resolve();
  }
  if (/^0+-*!$/.test(value) || /^0/.test(value) || !/^[0-9]+$/.test(value)) {
    return Promise.reject(
      new Error(`Please enter valid ${amountType} amount.`)
    );
  }
  return Promise.resolve();
};

export const getUserData = () => {
  const storedUserData = localStorage.getItem("userData");
  return storedUserData ? JSON.parse(storedUserData) : null;
};

export const storeUserData = (data: any) => {
  localStorage.setItem("userData", JSON.stringify(data));
  localStorage.setItem("authToken", data?.refresh_token || "");
  localStorage.setItem("bearerToken", data?.access_token || "");
};

export const clearStorageOnLogout = () => {
  localStorage.removeItem("userData");
  localStorage.removeItem("expiryTime");
  localStorage.removeItem("authToken");
  localStorage.removeItem("bearerToken");
};

export const getColorForStatus = (dpdStatus: string) => {
  let bg = "";
  if (dpdStatus === "DPD 0-30") {
    bg = "#6aa84f";
  }
  if (dpdStatus === "DPD 60-90") {
    bg = "#f1c232";
  }
  if (dpdStatus === "DPD 30-60") {
    bg = "#3d85c6";
  }
  if (dpdStatus === "DPD 90+") {
    bg = "#cc0000";
  }
  return bg;
};

export const decryptData = (encryptedData: any) => {
  const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_DECRYPT_SECRET_KEY);
  const iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_DECRYPT_INIT_VECTOR);

  if (encryptedData) {
    const decrypted = CryptoJS.AES.decrypt(encryptedData, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);
    return decryptedText;
  }
  return "";
};

export const getCasePayload = (state: any) => {
  if (state.activeTabKey === "2") {
    return state.activeCasePayload;
  }
  if (state.activeTabKey === "3") {
    return state.completedCasePayload;
  }
  if (state.activeTabKey === "1") {
    return state.pendingCasePayload;
  }
  return {};
};

export const getCasePayloadType = (tabKey: string) => {
  if (tabKey === "1") {
    return "SET_PENDING_CASE_PAYLOAD";
  }
  if (tabKey === "2") {
    return "SET_ACTIVE_CASE_PAYLOAD";
  }
  if (tabKey === "3") {
    return "SET_COMPLETED_CASE_PAYLOAD";
  }
};

export function isEmpty(value: any) {
  if (value === null || value === "null") {
    return true;
  } else if (typeof value !== 'number' && value === '') {
    return true;
  } else if (value === 'undefined' || value === undefined) {
    return true;
  } else if (value !== null && typeof value === 'object' && !Object.keys(value).length) {
    return true;
  } else {
    return false;
  }
}