import axios from "axios";
import {
  ADMIN_ACTIVE_CASES_ENDPOINT,
  ADMIN_COMPLETED_CASES_ENDPOINT,
  ADMIN_CREATE_USER_ENDPOINT,
  ADMIN_GET_USERS_ENDPOINT,
  ADMIN_MANAGERS_LIST_ENDPOINT,
  ADMIN_PENDING_CASES_ENDPOINT,
  ADMIN_ROLES_LIST_ENPOINT,
  ADMIN_UPDATE_AGENTS,
  ADMIN_UPDATE_USER_STATUS_ENDPOINT,
} from "../constants/endpoints";

type UpdateUserDetailsBodyTypes = {
  firstName: string | "";
  lastName: string | "";
  email: string | "";
  realmRoles: string[];
  mobileNo: string | number;
};

type CasesBodyType = {
  dpdStatus: string;
  endDate: string;
  maxDueAmount: string;
  maxLoanAmount: string;
  minDueAmount: string;
  minLoanAmount: string;
  nbfc: string;
  page: number;
  size: number;
  startDate: string;
  status: string;
  sortBy: string;
  sortOrder: string
};
export function getUsers(page: number, size: number) {
  const endpoint = `${ADMIN_GET_USERS_ENDPOINT}?pageNumber=${page}&pageSize=${size}`;
  return axios.get(endpoint);
}

export function updateUserDetails(body: UpdateUserDetailsBodyTypes) {
  return axios.put(ADMIN_UPDATE_AGENTS, body);
}

export async function getAdminActiveCases(body: CasesBodyType) {
  const res = await axios.post(ADMIN_ACTIVE_CASES_ENDPOINT, body);
  if (res.status === 200) {
    return res.data;
  }
}

export async function getAdminPendingCases() {
  const res = await axios.get(ADMIN_PENDING_CASES_ENDPOINT);
  if (res.status === 200) {
    return res.data;
  }
}
export async function getAdminCompletedCases(body: CasesBodyType) {
  const res = await axios.post(ADMIN_COMPLETED_CASES_ENDPOINT, body);
  if (res.status === 200) {
    return res.data;
  }
}

export function getManagers() {
  return axios.get(ADMIN_MANAGERS_LIST_ENDPOINT);
}

export async function getAllRoles() {
  const res = await axios.get(ADMIN_ROLES_LIST_ENPOINT);
  if (res.status === 200) {
    return res.data;
  }
}

export function createUser(body: any) {
  return axios.post(ADMIN_CREATE_USER_ENDPOINT, body);
}

export function updateUserStatus(body: {
  email: string | "";
  enable: false | true;
}) {
  return axios.put(ADMIN_UPDATE_USER_STATUS_ENDPOINT, body);
}
