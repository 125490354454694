import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Col, Row, Select, Upload, notification } from "antd";

import AppLayout from "../../components/Layout";
import { getClientList } from "../../services/commonController";

const DataUpload = () => {
  const [selectedNBFC, setSelectedNBFC] = useState(undefined);
  const [fileList, setFileList] = useState([]);
  const [info, setInfo]: any = useState({});
  const [fileUrl, setFileUrl] = useState("");
  const [nbfc, setNbfc] = useState("");
  const { data } = useQuery({
    queryKey: ["clientList"],
    queryFn: getClientList,
  });

  const props = {
    name: "file",
    action: `${process.env.REACT_APP_API_BASE_URL}common/upload?clientId=${nbfc}`,
    headers: {
      authorization: `Bearer ${localStorage.getItem("bearerToken")}`,
    },

    onChange(info: any) {
      setInfo(info);
      setFileList(info?.fileList);
      if (info.file.status !== "uploading") {
      }
      if (info.file.status === "done") {
        notification.success({
          message: "Success",
          description: info?.file?.response,
        });
        setTimeout(() => {
          setFileList([]);
        }, 3000);
        setSelectedNBFC(undefined);
      } else if (info.file.status === "error") {
        notification.error({
          message: "Error",
          description: info?.file.response?.error,
        });
      }
    },

    fileList,
    beforeUpload: (file: any) => {
      const isXLSX =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

      if (!isXLSX) {
        notification.error({
          message: "Error",
          description: "File format not supported. Please upload an XLSX file.",
        });
      }

      return isXLSX;
    },
  };

  const handleDownloadSample = () => {
    if (fileUrl) {
      window.open(fileUrl, "_blank");
    }
  };

  return (
    <AppLayout moduleName="Data Upload">
      <Row className="mb-64 gap-4">
        <Col span={4}>
          <Select
            onClear={() => setFileList([])}
            style={{ width: "100%" }}
            placeholder="Select Lender"
            allowClear
            onChange={(e) => setSelectedNBFC(e)}
            value={selectedNBFC}
            onSelect={(e, options) => {
              setNbfc(options?.key);
              setFileUrl(options?.fileUrl);
            }}
            options={data?.clients?.map((item: any) => ({
              key: item.clientId,
              value: item.clientId,
              label: item.clientName,
              fileUrl: item.fileUrl,
            }))}
          />
        </Col>
        <Button
          type="primary"
          icon={<DownloadOutlined />}
          disabled={!selectedNBFC}
          onClick={handleDownloadSample}
        >
          Download Sample Excel
        </Button>
        <Upload {...props}>
          <Button
            loading={info?.file?.status === "uploading"}
            type="primary"
            icon={<UploadOutlined />}
            disabled={!selectedNBFC}
          >
            Upload Excel
          </Button>
        </Upload>
      </Row>
    </AppLayout>
  );
};

export default DataUpload;
