import { Col, Row, Tabs, Typography } from "antd";
// import { DownloadOutlined, FilterOutlined, SortAscendingOutlined } from "@ant-design/icons";

import AdminDashboard from "../../components/Dashboards/AdminDashboard";
import AgentDashboard from "../../components/Dashboards/AgentDashboard";
import AgentManagerDashboard from "../../components/Dashboards/AgentManagerDashboard";
import AppLayout from "../../components/Layout";
import { AdminCasesTable } from "../../components/Dashboards/Tables/AdminCasesTable";

import { getUserData } from "../../utils";

const { Text } = Typography;

const Portfolio = () => {


  const userData = getUserData();
  const userRole = userData?.roleName;
  if (userRole === "Admin") {
    return <AdminDashboard />;
  }
  if (userRole === "Agent") {
    return <AgentDashboard />;
  }
  if (userRole === "Agent-Manager") {
    return <AgentManagerDashboard />;
  }

  const items = [
    {
      label: "Pending Case",
      key: "1",
      children: <AdminCasesTable data={[]} />,
    },
    {
      label: "Active Cases",
      key: "2",
      children: <AdminCasesTable data={[]} />,
    },
    {
      label: "Resolved Cases",
      key: "3",
      children: <AdminCasesTable data={[]} />,
    },
  ];

  return (
    <AppLayout moduleName="Portfolio">
      <Row className="flex justify-between pl-2 pr-2 pb-4">
        <Col></Col>
        {/* <Col className="flex gap-5">
          <Col className="flex gap-1">
            <DownloadOutlined className="cursor-pointer text-lg" />
            <Text>Download</Text>
          </Col>
          <Col className="flex gap-1">
            <SortAscendingOutlined className="cursor-pointer text-lg" />
            <Text>Sort</Text>
          </Col>
          <Col className="flex gap-1">
            <FilterOutlined className="cursor-pointer text-lg" />
            <Text>Filter</Text>
          </Col>
        </Col> */}
      </Row>
      <Tabs items={items}></Tabs>
    </AppLayout>
  );
};

export default Portfolio;
